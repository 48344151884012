import PropTypes from "prop-types";
import React from "react";
import { Navbar, Nav } from "react-bootstrap";

const Header = ({ siteTitle }) => (
  <header className="container">
    <Navbar bg="" expand="lg">
      <Navbar.Brand href="#home"><span className="logo-brackets">{'{ }'}</span>ne Click Away IT Solutions</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
    </Navbar>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
